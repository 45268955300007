import "../components/annotation.css";
import React,{Component} from "react"
import closeIcon from "../img/close-icon2.png"

export default function({content, handleClose}){
    if (!content) return null;
    return (
        <div className="annotationBackground">
            <div className="closeContainer">
                <a>
                <img onClick=  {() => handleClose()} src={closeIcon}/> </a>
                <a className="closetext" onClick=  {() => handleClose()}>schließen</a>
            </div>
            <div className="contentContainer" dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
    )
}