import React,{Component} from "react"
import "../components/arrow.css"

import arrowIconLeft from "../img/nachstessystemlinks.png"
import arrowIconRight from "../img/nachstessystemrechtspng.png"
import arrowIconLeftwhite from "../img/naechstessystemlinkswhite.png"
import arrowIconRightwhite from "../img/naechstessystemrechtswhite.png"

export default class Arrow extends Component {

    handleSystemChange(dir) {
      let iframe = this.props.ifr;
      iframe.contentWindow.postMessage({ "command": "updateModel", direction: dir}, "*");
      if (dir === true) {
        this.props.incSystem();
      } else {
        this.props.decSystem();
      }
    }

    render(){           
      return ( 
        <React.Fragment>
            <div className="arrowleft">
                <a>
                  <img onClick={() => {this.handleSystemChange(false)}} src={arrowIconLeftwhite} width="160px" alt="arrowicon"/>
                </a>
            </div>
            <div  className="arrowright">
                <a>
                  <img onClick={() => {this.handleSystemChange(true)}} src={arrowIconRightwhite} width="160px" alt="arrowicon"/>
                </a>
            </div>
        </React.Fragment>
        );
    }
  }