import React,{Component} from "react"
import "../components/hausonainfo.css"

import burnerIcon from "../img/506_00-VG_burner_level 3.png"
import buildingshapeIcon from "../img/442_01-VG_building_shape_l_shaped.png"

import temperatureIcon from "../img/016_00-VG_temperature.png"

export default class HausonaInfo extends Component {

    constructor(){
        super();
    
        this.state = {
            showHausonaInfo: true,
        }

        this.handleMessages = (e) => {
            const type = e.data.type;
            const objectTitle = e.data.objectTitle;
            const objectContent = e.data.content;
            
            if (type === "sphereAnnotationClosed"){
                this.setState({showHausonaInfo:true,})
              }
            if (type === "sphereAnnotationTriggered"){
                this.props.setHausonaInfoFalse();
            }
        }

        if (typeof window !== 'undefined') {
            window.addEventListener("message", this.handleMessages);
        }

    }
    

    handleSystemID(id) {
        let iframe = this.props.ifr;
        iframe.contentWindow.postMessage({ "command": "updateModel", systemID: id}, "*");
        this.props.setsystemId(id);
    }

    
    componentWillUnmount(){
        
        if (typeof window !== 'undefined') {
        window.removeEventListener("message", this.handleMessages);
        }    

    }

    determineIfActive(id){
        if (this.props.systemId == id){
            return ("button active")
        } else {
            return ("button")
        }
    }

    determineSystemIfActive(id){
        if (this.props.systemId == id){
            return ("systeminformationactive")
        } else {
            return ("systeminformation")
        }
    }

    render(){           
      return ( 
        <div>
        {this.props.showHausonaInfo && (
        <React.Fragment>
            <div className="infoboxContainer">
                    <div className="imageContainer">
                        <img src={this.props.image} className="topimage" alt="Hausona Bild"/>
                        <div className="title">{this.props.title}</div>
                    </div>
                     
                    <div className= "iconcontainer">
                        <div className="icon-reihe1">
                            <div className="icon-content">
                                <img src={this.props.modernisationIcon} width="40px" alt="Icon1"/>
                                <p>{this.props.modernisation}</p>
                            </div>
                            <div className="icon-content">
                                <img src={burnerIcon} width="40px" alt="Icon1"/>
                                <p>{this.props.kWZahl}</p>
                            </div>
                            <div className="icon-content">
                                <img src={buildingshapeIcon} width="40px" alt="Icon1"/>
                                <p>{this.props.squaremeters}<sup>2</sup></p>
                            </div>
                        </div>
                        <div className="icon-reihe2">
                            <div className="icon-content">
                                <img src={this.props.heatingIcon} width="40px" alt="Icon1"/>
                                <p>{this.props.heating}</p>
                            </div >
                            <div className="icon-content">
                                <img src={temperatureIcon} width="40px" alt="Icon1"/>
                                <p>{this.props.temperature}</p>
                            </div>
                            <div className="icon-content">
                                <img src={this.props.exteriorIcon} width="40px" alt="Icon1"/>
                                <p>{this.props.renovation}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="textcontainer">
                        <div className="systemtitle">Systemlösungen</div>
                        <div className="systemcontainer">
                            <a className={this.determineIfActive(1)} title="aroTHERM Split 1" onClick={() => {this.handleSystemID(1)}}></a>
                            <a onClick={() => {this.handleSystemID(1)}} className={this.determineSystemIfActive(1)}>{this.props.systeminfo1}</a>
                        </div>
                        <div className="systemcontainer">
                            <a className={this.determineIfActive(2)} title="aroTHERM Split 1" onClick={() => {this.handleSystemID(2)}}></a>
                            <a onClick={() => {this.handleSystemID(2)}} className={this.determineSystemIfActive(2)}>{this.props.systeminfo2}</a>
                        </div>
                        <div className="systemcontainer">
                            <a className={this.determineIfActive(3)} title="aroTHERM Split 1" onClick={() => {this.handleSystemID(3)}}></a>
                            <a onClick={() => {this.handleSystemID(3)}} className={this.determineSystemIfActive(3)}>{this.props.systeminfo3}</a>
                        </div>
                    </div>
                
            </div>
        </React.Fragment>
        )}
        </div>
        );
    }
  }