import React,{Component} from "react"
import HausonaInfo from "../components/hausonainfo.js"
import Annotation from "../components/annotation.js"
import Arrow from "../components/arrow.js"

import vaillantLogo from "../img/vaillant-logo.png"
import closeIcon from "../img/close-icon2.png"
import backIcon from "../img/back-icon-grey.png"
import forwardIcon from "../img/forward-icon2.png"
import hausonabild1 from "../img/hausona1.png"
import hausonabild2 from "../img/hausona2.png"
import hausonabild3 from "../img/hausona3.png"
import modernisationIcon from "../img/157_01-VG_modernisation.png"
import newhouseIcon from "../img/157_01-VG_new_single.png"
import heatingIcon from "../img/001_00-VG_heating.png"
import floorheatingIcon from "../img/001_00-VG_floorheating.png"
import exteriorIcon from "../img/416_00-VG_exterior_wall.png"
import exteriorNewIcon from "../img/hauson3icon6.png"

import "../styles/global.css"

export default class Home extends Component {
  constructor(){
    super();
    this.mainIframe = React.createRef();

    this.state = {
      random: 0,
      showMenuCity: true,
      showMenuHaus: false,
      showMenuHausona1: false,
      showMenuHausona2: false,
      showMenuHausona3: false,
      currentsystem: 1,
      content: "",
      showHausonaInfo: true,
    }

    this.handleMessages = (e) => {
      // const annotationID = e.data.annotationID ?  Number(e.data.annotationID) : 0;
      const objectTitle = e.data.objectTitle;
      // const isTriggeredFromArrows = e.data.isTriggeredFromArrows;
      const type = e.data.type;
      console.log("Das sind die Events:" + type);
      if (type === "sphereLoadModel" && objectTitle === "Hausona1"){
            this.setState({
             showMenuCity: false,
             showMenuHaus: true,
             showMenuHausona1: true,
             currentsystem: 1,
             content: "",
           })
      }

      

      if (type === "sphereLoadModel" && objectTitle === "Hausona2"){
        this.setState({
         showMenuCity: false,
         showMenuHaus: true,
         showMenuHausona2: true,
         currentsystem: 1,
         content: "",
       })
      }

      if (type === "sphereLoadModel" && objectTitle === "Hausona3"){
        this.setState({
         showMenuCity: false,
         showMenuHaus: true,
         showMenuHausona3: true,
         currentsystem: 1,
         content: "",
       })
      }

      if (type === "sphereAnnotationTriggered"){
        let content = e.data.content;
        this.setState({content});
      }

      if (type === "sphereAnnotationClosed"){
        // if(this.state.showMenuCity == false){
          let iframe = this.mainIframe.current;
          iframe.contentWindow.postMessage({ "command": "resetModel"}, "*");
        // }
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener("message", this.handleMessages);
    }
  }


  reload(){
      this.setState({
        random: this.state.random + 1,
        showMenuCity: true,
        showMenuHaus: false,
        showMenuHausona1: false,
        showMenuHausona2: false,
        showMenuHausona3: false,

      })
  }

  handleCloseAnnotation(){
    let iframe = this.mainIframe.current;
    iframe.contentWindow.postMessage({ "command": "resetModel"}, "*");
    this.setState({
      content: "",
      showHausonaInfo: true,
    })
  }

  handleClick(id) {
    let iframe = this.mainIframe.current;
    iframe.contentWindow.postMessage({ "command": "triggerAnnotation", "annotationId": id, "objectTitle": ""}, "*");
  }

  handleSystemChange(dir) {
    let iframe = this.mainIframe.current;
    iframe.contentWindow.postMessage({ "command": "updateModel", direction: dir}, "*");
  }

  componentWillUnmount(){
    
    if (typeof window !== 'undefined') {
      window.removeEventListener("message", this.handleMessages);
    }    

  }

  increaseSystemId(){
    let systemid= this.state.currentsystem;
    if (systemid < 3){
      systemid+=1;
    } else if (systemid >= 3){
      systemid =1;
    }
    this.setState({currentsystem: systemid});
  }

  decreaseSystemId(){
    let systemid= this.state.currentsystem;
    if (systemid > 1){
      systemid-=1;
    } else if (systemid <= 1){
      systemid =3;
    }
    this.setState({currentsystem: systemid});
  }

  setSystemId(id){
    let systemid= id;
    this.setState({currentsystem: systemid});
  }

  setHausonaInfoFalse(){
    this.setState({showHausonaInfo: false})
  }

  render(){           
    return ( 
      <div>  
        <div className="devicezoom">
        { this.state.showMenuCity && (
          <React.Fragment>
            <div className="menucontainer">
              <div className="menu top">
                <a  onClick={() => this.reload()}>
                  <img src={vaillantLogo} width="140px" alt="Vaillant Logo"/>
                </a>
              </div>

              <div className="menu sub">
                <a className="firstitem" onClick={() => {this.handleClick(1)}}>Live-Eventbereich</a>
                <div className="imagecenter">
                  <img src={forwardIcon} alt="Forward Icon" height="30px" />
                </div>  
              </div>

              <div className="menu sub">
                <p>Hausonas Modernisierung</p>
                <div className="imagecenter">
                  <img src={forwardIcon} alt="Forward Icon" height="30px" />
                </div>  
                <div className="dropdown">
                  <div className="dropdownItem dropdowntop">
                    <a className="imagecenter" onClick={() => {this.handleClick(3)}} >Einfamilienhaus bis 1978 mit Heizkörpern - unsaniert</a>
                  </div>
                  <div className="dropdownItem dropdownbottom">
                    <a className="imagecenter" onClick={() => {this.handleClick(2)}}>8-Parteien-Haus 70er Jahre</a>
                  </div>
                </div>   
              </div>
              
                <div className="menu sub bottom">
                    <p>Hausonas Neubau</p>
                    <div className="imagecenter">
                      <img src={forwardIcon} alt="Forward Icon" height="30px"/>
                    </div>  
                    <div className="dropdown">
                      <div className="dropdownItem dropdownsingle">
                        <a onClick={() => {this.handleClick(4)}} className="imagecenter">Einfamilienhaus GEG-Musterhaus</a>
                      </div>
                    </div> 
                </div>
            </div>
          </React.Fragment>
        )}

        { this.state.showMenuHaus && this.state.content =="" && (
          <React.Fragment>
            
            <div className="menucontainer">
              <div className="menu single">
                <a onClick={() => this.reload()} className="imagecenter">
                  <img src={backIcon} width="30px" alt="Close Icon"/>
                </a>
                <a className="menuborder" onClick={() => this.reload()}>
                  <img src={vaillantLogo} width="140px" alt="Vaillant Logo"/>
                </a>
              </div>
            </div>
            {/*<Arrow ifr={this.mainIframe.current}  incSystem={() => this.increaseSystemId()} decSystem={() => this.decreaseSystemId()}></Arrow>*/}
          </React.Fragment>
        )}

        { this.state.showMenuHausona1 && (
          <React.Fragment>
            <HausonaInfo showHausonaInfo={this.state.showHausonaInfo} setHausonaInfoFalse={() => this.setHausonaInfoFalse()} systemId={this.state.currentsystem} setsystemId={(id) => this.setSystemId(id)} ifr={this.mainIframe.current} image={hausonabild1} title="Einfamilienhaus bis 1978 mit Heizkörpern - unsaniert" modernisationIcon={modernisationIcon} heatingIcon={heatingIcon} exteriorIcon={exteriorIcon} modernisation="1960" kWZahl="20 kW" squaremeters="170 m" heating="Radiatoren" temperature="65 °C" renovation="unsaniert"  systeminfo1="Hybridheizung ecoTEC plus mit aroTHERM Split" systeminfo2="Hybridheizung ecoTEC plus mit solarer Heizungsunterstützung" systeminfo3="Gas-Brennwert ecoVIT mit Brauchwasserwärmepumpe aroSTOR" ></HausonaInfo>
          </React.Fragment>
        )}

        { this.state.showMenuHausona2 && (
          <React.Fragment>
            <HausonaInfo showHausonaInfo={this.state.showHausonaInfo} setHausonaInfoFalse={() => this.setHausonaInfoFalse()} systemId={this.state.currentsystem} setsystemId={(id) => this.setSystemId(id)} ifr={this.mainIframe.current} image={hausonabild2} title ="8-Parteien Haus 70er Jahre" modernisationIcon={modernisationIcon} heatingIcon={heatingIcon} exteriorIcon={exteriorIcon}modernisation="1969-1978" kWZahl="31 kW" squaremeters="430 m" heating="Radiatoren" temperature="65 °C" renovation="unsaniert" systeminfo1="Hybridheizung ecoTEC plus mit aroTHERM plus" systeminfo2="Luft/Wasser-Wärmepumpe aroTHERM perform" systeminfo3="Hybridheizung ecoTEC plus mit solarer Heizungsunterstützung"></HausonaInfo>
          </React.Fragment>
        )}

        { this.state.showMenuHausona3 && (
          <React.Fragment>
            <HausonaInfo showHausonaInfo={this.state.showHausonaInfo} setHausonaInfoFalse={() => this.setHausonaInfoFalse()} systemId={this.state.currentsystem} setsystemId={(id) => this.setSystemId(id)} ifr={this.mainIframe.current} image={hausonabild3} title ="Einfamilienhaus GEG-Musterhaus" modernisationIcon={newhouseIcon} heatingIcon={floorheatingIcon} exteriorIcon={exteriorNewIcon} modernisation="2021" kWZahl="5kW" squaremeters="143m" heating="Fußbodenheizung" temperature="35°C" renovation="KfW EH 55" systeminfo1="Luft/Wasser-Wärmepumpe aroTHERM plus mit uniTOWER" systeminfo2="ecoTEC exclusive mit Brauchwasserwärmepumpe aroSTOR und zentraler Lüftung recoVAIR" systeminfo3="Sole/Wasser-Wärmepumpe flexoCOMPACT und zentraler Lüftung recoVAIR" ></HausonaInfo>
          </React.Fragment>
        )}
        <div className="mobile">
          <div className="mobilebg">
            <div className="mobileimg"><img src={vaillantLogo}/></div>
            <div className="mobiletext"><p>Diese Anwendung ist nicht für Mobilgeräte geeignet.</p></div>
            <div className="mobiletext"><p>Bitte wechseln Sie zu einem Desktopgerät oder Tablet (Landscape Mode).</p></div>
          </div>
        </div>
        </div>

        
        <div className="hausonaAnnotationContainer">
          
          { !this.state.showMenuCity && (
          <Annotation content={this.state.content} handleClose={() => this.handleCloseAnnotation()}></Annotation> 
          )}
          <iframe key={this.state.random}  src={"https://my.zrealitysphere.com/api/hosting/C2Q7TENNNAW1V1IQRO6XDELLP2TT6FZZ/index.html"} ref={this.mainIframe} className="iframe" title="Vaillant City"></iframe>
        </div>
      </div>
      
      );
  }
}